import "../../../subPages/sub.css";
import "./Nord.css";
import "../mobileSubStyle.css";
import { Player } from "video-react";
import "video-react/dist/video-react.css";
import { useEffect, useRef } from "react";

const Nord = () => {
  return (
    <div className="subContainer">
      <div className="subContentWrapper">
        <div className="subContentArea mobileCity nord">
          <img src="/assets/img/motiv_nord.png"></img>
          <div>
            <div>
              <div className="mobileHeaderSegment">
                <h2>
                  BEST CARWASH NORD <br />
                  POPPENBÜTTELER WEG
                </h2>
                <div>
                  <img
                    className="sm"
                    src="/assets/img/facebook_einzeln.png"
                    onClick={() => {
                      window.location.href =
                        "https://www.facebook.com/BCWHHNORD/";
                    }}
                  ></img>
                  <img
                    className="sm"
                    src="/assets/img/instaEinzeln.png"
                    onClick={() => {
                      window.location.href =
                        "https://www.instagram.com/bestcarwashpoppenbuettel/?hl=de";
                    }}
                  ></img>
                </div>
              </div>
              <div className="videoWrapper">
                {/* <Player
                  playsInline
                  poster="/assets/poster.png"
                  src="/assets/Videos/trailer_city.mp4"
                /> */}
                <video
                  controls
                  autoplay="autoplay"
                  muted="muted"
                  loop="loop"
                  playsinline="playsinline"
                  preload="metadata"
                >
                  <source
                    src="/assets/Videos/BEST_CARWASH_POPPENBUETTEL.mp4"
                    type="video/mp4"
                  />
                </video>
              </div>

              <div className="pageContentWrapper">
                <h2>ERFAHR DEN UNTERSCHIED </h2>
                <h4 className="subTextBlock">
                  Seit knapp 30 Jahren gibt es BEST CARWASH in Hamburg-Nord, am
                  Ring 3, im Poppenbütteler Weg. Wer einmal bei uns war, der
                  weiß: Autowaschen ist nicht gleich Autowaschen, denn wir leben
                  unser Motto „ERFAHR DEN UNTERSCHIED“ jeden Tag auf's Neue.
                  <br />
                  <br />
                  Bei uns gibt es modernste Technik und echte Handarbeit mit
                  viel Persönlichkeit nicht nur beim Autowaschen, sondern auch
                  bei der Innenreinigung und Innenaufbereitung von Fahrzeugen.
                  <br />
                  <br />
                  Unsere langjährigen Mitarbeiterinnen und Mitarbeiter sind
                  speziell geschult & ausge- bildet – und beraten Sie kompetent
                  und mit Sachverstand bei allen Fragen rund ums Thema „Waschen
                  & Pflegen“.
                  <br />
                  <br />
                  Wir kennen unsere Stammkunden – und unsere Stammkunden kennen
                  uns. Bei BEST CARWASH Nord in Poppenbüttel verbindet man
                  Kompetenz und Beratung mit konkreten Gesichtern und Namen. Und
                  das seit vielen Jahren...
                  <br />
                  <br />
                  Seit einiger Zeit präsentieren wir uns in einem neuen und
                  modernen Look unter dem selbst- bewussten Motto:
                  <br />
                  <br />
                  "Erfahr den Unterschied"
                  <br />
                  <br />
                  Wann fahren Sie los, um diese Unterschied-Erfahrung zu machen?
                </h4>
              </div>

              <div>
                <h4>BEST CARWASH NORD</h4>
                <h4>Poppenbütteler Weg 94</h4>
                <h4>22339 Hamburg</h4>
              </div>
              <div>
                <h4>
                  <a style={{ color: "blue" }} href="tel:0405381161">
                    Tel | 040-538 11 61
                  </a>
                </h4>
                <h4>
                  <a
                    style={{ color: "blue" }}
                    href="mailto:poppenbuettel@bestcarwash-hamburg.de"
                  >
                    Mail | poppenbuettel@bestcarwash-hamburg.de
                  </a>
                </h4>
              </div>
              <div>
                <h4>
                  Öffnungszeiten <br /> MO-FR 8:00-19:00 <br /> SA 8:00-18:00
                  <br /> SO 13:00-18:00 <br /> <br /> An gesetzlichen Feiertagen
                  geschlossen
                </h4>
              </div>

              <div className="pageContentWrapper">
                <img src="/assets/img/BEST BUSINESS.png"></img>
                <h2 className="lightHeader">
                  WashManagement <br />
                  für Fahrzeugflotten
                </h2>
                <img src="/assets/img/MOTIV_BUSINESS.png"></img>
                <h2 className="lightHeader">BEST Business</h2>
                <h4 className="subTextBlock">
                  Für Ihre Firmen-Fahrzeugflotte entwickeln wir zusammen mit
                  Ihnen ein maßgeschnei- dertes Wasch- & Pflege-Paket, das
                  perfekt auf Ihre Anforderungen abgestimmt ist.
                </h4>
                <h2 className="lightHeader below">
                  Speziell für Firmenfahrzeuge
                </h2>
                <h4 className="subTextBlock list">
                  - Fahrzeugwäschen <br />
                  - Fahrzeugpflege | Schutz & Glanz <br />
                  - Innenreinigungen <br />- Fahrzeug-Aufbereitungen
                </h4>
                <p className="nordTextBlock first">
                  <strong>BEST Qualität</strong> <br />
                  Profitieren Sie von unserer Erfahrung & Zuverlässigkeit.
                </p>

                <p className="nordTextBlock">
                  <strong>BEST Preis</strong>
                  <br />
                  Überzeugende Preise bedeutet, dass Sie bei uns keine
                  überflüssigen Leistungen bezahlen.
                </p>
                <p className="nordTextBlock">
                  <strong>BEST Contact</strong>
                  <br />
                  Sie werden von einem persönlichen Ansprechpartner individuell
                  betreut.
                </p>
                <p className="nordTextBlock">
                  <strong>BEST Service</strong>
                  <br />
                  Unserere verschiedenen Services sind speziell auf
                  Firmen-Fahrzeuge ausgelegt und garantieren so einen optimalen
                  Wert- erhalt. Auch wir wissen: Nur ein perfekt gepflegter
                  Fuhrpark repräsentiert Ihre Firma optimal... <br />
                </p>
                <p className="nordTextBlock">
                  <strong>BEST Flexi</strong>
                  <br /> Wir betreuen alle Autotypen - von Smart über Mercedes
                  G-Klasse bis hin zu Transportern.
                </p>
                <h2 className="lightHeader divider">Und so geht's...</h2>
                <p className="nordTextBlock">
                  <strong>kostenlose Test-Wäsche</strong>
                  <br />
                  Überzeugen Sie sich von unserer Qualität. Als
                  Business-Neukunde können Sie uns jederzeit mit einer
                  kostenlosen Wäsche testen.
                </p>
                <p className="nordTextBlock">
                  <strong>BEST Business-Card beantragen</strong>
                  <br />
                  Auf Wunsch können Sie danach Ihre BEST Business-Card vor Ort
                  beantragen und sofort von allen Vorteilen profitieren.
                </p>
                <p className="nordTextBlock">
                  <strong>Noch Fragen...?</strong>
                  <br />
                  Sie wünschen eine persönliche Beratung für Ihre
                  Fahrzeugflotte? Dann rufen Sie uns gerne an!
                  <br />
                </p>
                <p className="nordTextBlock">
                  Wir beraten Sie professionell & individuell. <br />
                  <span>040-538 11 61</span>
                </p>
              </div>

              <div className="pageContentWrapper">
                <h2 className="lightHeader">BEST Cabrio</h2>
                <img src="/assets/img/MOTIV VERDECK.png"></img>
                <h2 className="lightHeader">
                  Cabrio-Verdeck reinigen & versiegeln...{" "}
                </h2>

                <p className="nordTextBlock">
                  ... das ist der professionelle Doppel-Pack für Ihr
                  Cabrio-Verdeck. Für einen Cabrio-Verdeck-Termin rufen Sie uns
                  einfach an – oder schreiben eine E-Mail:
                  <br />
                  <span>
                    040-538 11 61 <br />
                    poppenbuettel@bestcarwash-hamburg.de
                  </span>
                </p>
              </div>

              <div className="pageContentWrapper">
                <h2 className="lightHeader">BEST Innenraum-Desinfektion</h2>
                <img src="/assets/img/MOTIV DESINFECTION.png"></img>

                <p className="nordTextBlock first">
                  <strong>BEST Innenraum-Desinfektion mit Verdampfer</strong>{" "}
                  <br />
                  Eine ausgeprägte Hygiene ist ein wichtigster Baustein, um die
                  aktuelle Ausbreitung des Corona-Viruses einzudämmen. Unsere
                  professionelle Innenraum-Desinfektion mit Verdampfer beseitigt
                  zuverlässig schlechte Gerüche, Bakterien, Pilze, Viren (u.a.
                  Covid-19), Milben und Schimmel. Leisten Sie ein aktiven
                  Beitrag zum Schutz von Fahrer und allen Mitfahrern.
                </p>

                <p className="nordTextBlock">
                  <strong>Die BEST Innenraum-Desinfektion... </strong>
                  <br />
                  ... ist für Allergiker geeignet.
                </p>
                <p className="nordTextBlock">
                  <strong>Die BEST Innenraum-Desinfektion... </strong>
                  <br />
                  ... reduziert Mikroorganismen, Pilze & Keime im
                  Fahrzeuginneren nachhaltig.
                </p>
                <p className="nordTextBlock">
                  <strong>Die BEST Innenraum-Desinfektion... </strong>
                  <br />
                  ... reduziert Mikroorganismen, Pilze & Keime im
                  Fahrzeuginneren nachhaltig.
                  <br />
                </p>
                <p className="nordTextBlock">
                  <strong>Die BEST Innenraum-Desinfektion... </strong>
                  <br /> ... sorgt für eine deutliche Verbesserung der
                  Luftqualität im gesamten Auto. <br />
                  <br />
                  Für einen Desinfektions-Termin rufen Sie uns einfach an – oder
                  schreiben eine E-Mail: <br />
                  <span>
                    040-538 11 61
                    <br /> poppenbuettel@bestcarwash-hamburg.de
                  </span>
                </p>
              </div>
            </div>

            <div className="pageContentWrapper">
              <img src="/assets/img/FOTO_ANLAGE_NORD___UNTEN.png"></img>
            </div>
          </div>
          <br/>
          <a className={"backHomeBtn"} href="/">
            <img src="/assets/img/mobile/backBtn.png"></img>
          </a>

        </div>
      </div>
      <img className="pfeilDown" src="/assets/img/mobile/pfeil.png"></img>
    </div>
  );
};

export default Nord;
